.hover-danger:hover {
  background-color: #ffd4d4 !important;
}
.hover-green:hover {
  background-color: #d7ffd4 !important;
}
.hover-blue:hover {
  background-color: #d4f5ff !important;
}
.pointer {
  cursor: pointer !important;
}
.not-allowed {
  cursor: not-allowed !important;
}
.disabled-section {
  background-color: #25252d80;
  opacity: 0.5;
}
/* .table-res {
  overflow: auto !important;
  white-space: nowrap !important;
}
.bg-main {
  background-color: #1e1e2d !important;
}
tbody tr td {
  padding: inherit !important;
} */
.bg-active {
  background-color: #1e1e2d !important;
}
.tag-purple {
  background-color: #f9f5ff !important;
  color: #6941c6 !important;
}
.tag-success {
  background-color: #ecfdf3 !important;
  color: #027a48 !important;
}
.tag-danger {
  background-color: #fef3f2 !important;
  color: #b42318 !important;
}
.tag-brown {
  background-color: #fffaeb !important;
  color: #b54708 !important;
}
.tag-primary {
  background-color: #e4f8fa !important;
  color: #04bac7 !important;
}
.tag-warning {
  background-color: #ffff00 !important;
  color: #b58105 !important;
}
.css-bkrceb-MuiButtonBase-root-MuiPickersDay-root.Mui-selected {
  background-color: #04bac7 !important;
}
.css-dnq7gs-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: #04bac7 !important;
}
.css-11yukd5-MuiTabs-indicator {
  background-color: #04bac7 !important;
}
.css-1gv0vcd-MuiSlider-track {
  background-color: #04bac7 !important;
  border: none !important;
  padding: 2px 0px;
}
.css-eg0mwd-MuiSlider-thumb {
  background-color: #04bac7 !important;
  border: none !important;
  padding: 2px 0px;
}
.css-14pt78w-MuiSlider-rail {
  background-color: #039ba6 !important;
  padding: 2px 0px;
}
.css-dxidv3-MuiSlider-root {
  color: #039ba6 !important;
}
.css-bbm1xv-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: #039ba6 !important;
}
.css-1317ea1-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: #039ba6 !important;
}
.hover-light:hover {
  background-color: #f3f4f6 !important;
  cursor: pointer;
  border-radius: 5px;
  border: 1px solid #b8b8b8 !important;
}
